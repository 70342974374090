export default {
  form: {
    'time-att-file': 'Upload File (.csv , .xlsx)',
    'mapping-att-file': 'Upload File (.csv , .xlsx)',
    'sp-all-att-file': 'Upload File (.csv , .xlsx)',
    'ot-att-file': 'Upload File (.csv , .xlsx)',
    'time-sync-sftp': 'Manual Sync Sftp file.',
    title: {
      'map-att': 'Mapping Employee Card id Document Management',
      'time-att': 'Time Employee Document Management',
      'sp-all-att': 'Special Allowance Employee Document Management',
      'ot-att': 'OT Employee Document Management'
    },
    button: {
      'sync-sftp': 'Sync Sftp'
    }
  },
  'import-table': {
    row: 'Row',
    empCode: 'Employee Code'
  },
  modal: {
    'sync-success': 'Sync Sftp Success.',
    'sync-success-title': 'Sync Complete.'
  },
  tabs: {
    'e-time': 'E-Time',
    'e-leave': 'E-Leave'
  },
  title: 'Import',
  home: 'Home'
}
